<template>
  <component-frame title="Buttons">
    <div class="sg-page__heading">
      <div class="on-this-page">
        <div class="on-this-page-title">On This Page</div>
        <ul class="on-this-page-anchor-list">
          <li><a href="#" v-scroll-to="'#primary'">Button Variants</a></li>
          <li>
            <a href="#" v-scroll-to="'#primary-secondary'">Primary and secondary</a>
          </li>
          <li>
            <a href="#" v-scroll-to="'#call-to-action'">Call to action</a>
          </li>
          <li><a href="#" v-scroll-to="'#utility'">Utility</a></li>
          <li><a href="#" v-scroll-to="'#disabled'">Disabled</a></li>
        </ul>
      </div>
    </div>

    <h3 id="primary">Button Variants</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view sg-component__view--buttons">
          <h5>Primary button (confirm, continue)</h5>
          <button class="btn btn-primary">Default</button>
          <button class="btn btn-primary hover">Hover</button>
          <button class="btn btn-primary active">Active</button>
          <button class="btn btn-primary focus">Focus</button>
          <button class="btn btn-primary" disabled>Disabled</button>
          <h5 class="mt-4">Secondary button (utility)</h5>
          <button class="btn btn-secondary">Default</button>
          <button class="btn btn-secondary hover">Hover</button>
          <button class="btn btn-secondary active">Active</button>
          <button class="btn btn-secondary focus">Focus</button>
          <button class="btn btn-secondary" disabled>Disabled</button>
          <h5 class="mt-4">Tertiary button (start flow, add item)</h5>
          <button class="btn btn-tertiary">Default</button>
          <button class="btn btn-tertiary hover">Hover</button>
          <button class="btn btn-tertiary active">Active</button>
          <button class="btn btn-tertiary focus">Focus</button>
          <button class="btn btn-tertiary" disabled>Disabled</button>
          <h5 class="mt-4">Danger button</h5>
          <button class="btn btn-danger">Default</button>
          <button class="btn btn-danger hover">Hover</button>
          <button class="btn btn-danger active">Active</button>
          <button class="btn btn-danger focus">Focus</button>
          <button class="btn btn-danger" disabled>Disabled</button>
          <h5 class="mt-4">Button link</h5>
          <button class="btn btn-link">Default</button>
          <button class="btn btn-link hover">Hover</button>
          <button class="btn btn-link active">Active</button>
          <button class="btn btn-link focus">Focus</button>
          <button class="btn btn-link" disabled>Disabled</button>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>Show Code</div>
          <pre v-highlight><code class="language-html">
&lt;h5&gt;Primary button&lt;/h5&gt;
&lt;button class="btn btn-primary"&gt;Default&lt;/button&gt;
&lt;button class="btn btn-primary hover"&gt;Hover&lt;/button&gt;
&lt;button class="btn btn-primary active"&gt;Active&lt;/button&gt;
&lt;button class="btn btn-primary focus"&gt;Focus&lt;/button&gt;
&lt;button class="btn btn-primary" disabled&gt;Disabled&lt;/button&gt;
&lt;h5&gt;Secondary button&lt;/h5&gt;
&lt;button class="btn btn-secondary"&gt;Default&lt;/button&gt;
&lt;button class="btn btn-secondary hover"&gt;Hover&lt;/button&gt;
&lt;button class="btn btn-secondary active"&gt;Active&lt;/button&gt;
&lt;button class="btn btn-secondary focus"&gt;Focus&lt;/button&gt;
&lt;button class="btn btn-secondary" disabled&gt;Disabled&lt;/button&gt;
&lt;h5&gt;Danger button&lt;/h5&gt;
&lt;button class="btn btn-danger"&gt;Default&lt;/button&gt;
&lt;button class="btn btn-danger hover"&gt;Hover&lt;/button&gt;
&lt;button class="btn btn-danger active"&gt;Active&lt;/button&gt;
&lt;button class="btn btn-danger focus"&gt;Focus&lt;/button&gt;
&lt;button class="btn btn-danger" disabled&gt;Disabled&lt;/button&gt;
&lt;h5&gt;Button link&lt;/h5&gt;
&lt;button class="btn btn-link"&gt;Default&lt;/button&gt;
&lt;button class="btn btn-link hover"&gt;Hover&lt;/button&gt;
&lt;button class="btn btn-link active"&gt;Active&lt;/button&gt;
&lt;button class="btn btn-link focus"&gt;Focus&lt;/button&gt;
&lt;button class="btn btn-link" disabled&gt;Disabled&lt;/button&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>There should only be one primary action button on a page</li>
          <li>Button label should be as short as possible and describe the action it performs</li>
          <li>Avoid non descriptive words like "click here", "next", or "go"</li>
        </ul>
      </div>
    </div>

    <h3 id="primary">Button Sizes</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view sg-component__view--buttons">
          <button class="btn btn-lg btn-secondary">Large</button>
          <button class="btn btn-secondary">Normal</button>
          <button class="btn btn-md btn-secondary">Medium</button>
          <button class="btn btn-sm btn-secondary">Small</button>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>Show Code</div>
          <pre v-highlight><code class="language-html">
&lt;button class="btn btn-lg btn-secondary"&gt;Large&lt;/button&gt;
&lt;button class="btn btn-secondary"&gt;Normal&lt;/button&gt;
&lt;button class="btn btn-md btn-secondary"&gt;Small&lt;/button&gt;
&lt;button class="btn btn-sm btn-secondary"&gt;Small&lt;/button&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>Use large buttons for call to action</li>
          <li>Use normal size buttons in forms and modals</li>
          <li>Use small and x-small buttons when they're in tight spaces, such as a table header. Small buttons should not be grouped.</li>
          <li>Large: 44px tall - 48px padding</li>
          <li>Normal: 44px tall - 24px padding</li>
          <li>Small: 27px tall - 12px padding</li>
          <li>X-small: 25px tall - 10px padding</li>
        </ul>
      </div>
    </div>

    <h3 id="primary-secondary">Primary with secondary action</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div class="d-flex flex-column-reverse flex-sm-row justify-content-sm-between d-print-none">
            <div class="mt-2 mt-sm-0">
              <button class="btn btn-secondary">Back</button>
            </div>
            <div class="d-flex justify-content-between">
              <button class="btn btn-secondary mr-1">Save Progress</button>
              <button class="btn btn-primary">
                Continue
                <svg class="icon icon-arrow_cta ml-1">
                  <use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>Show Code</div>
          <pre v-highlight><code class="language-html">
&lt;div class="d-flex flex-column-reverse flex-sm-row justify-content-sm-between d-print-none"&gt;
  &lt;div class="mt-2 mt-sm-0"&gt;
    &lt;button class="btn btn-secondary"&gt;Back&lt;/button&gt;
  &lt;/div&gt;
  &lt;div class="d-flex justify-content-between"&gt;
    &lt;button class="btn btn-secondary mr-1"&gt;Save Progress&lt;/button&gt;
    &lt;button class="btn btn-primary"&gt;Continue &lt;svg class="icon icon-arrow_cta ml-1"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"&gt;&lt;/use&gt;&lt;/svg&gt;&lt;/button&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>Primary and secondary actions should be clearly seperated</li>
          <li>If there's an option to abandon a flow, this should be separated from the primary and secondary actions</li>
        </ul>
      </div>
    </div>

    <h3 id="call-to-action">Call To Action</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <h5>Start new flow</h5>
          <a class="btn btn-lg btn-tertiary" href="javascript:void(0)">
            <svg class="icon icon-add">
              <use xlink:href="/icons/symbol-defs.svg#icon-add"></use>
            </svg>
            Create a Package
          </a>
          <h5 class="mt-3">Primary page action / continue flow</h5>
          <button class="btn btn-lg btn-primary">
            Save and Continue
            <svg class="icon icon-arrow_cta">
              <use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use>
            </svg>
          </button>

          <h5 class="mt-3">Secondary call to action</h5>
          <a class="btn btn-md btn-secondary px-2" href="javascript:void(0)">
            Prepare Submission
            <svg class="icon icon-arrow_cta">
              <use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use>
            </svg>
          </a>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>Show Code</div>
          <pre v-highlight><code class="language-html">
&lt;a class="btn btn-lg btn-tertiary" href="javascript:void(0)"&gt;
  &lt;svg class="icon icon-add"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-add"&gt;&lt;/use&gt;&lt;/svg&gt;
  Create a Package
&lt;/a&gt;
&lt;button class="btn btn-lg btn-primary"&gt;
  Save and Continue
  &lt;svg class="icon icon-arrow_cta"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"&gt;&lt;/use&gt;&lt;/svg&gt;
&lt;/button&gt;
&lt;a class="btn btn-md btn-secondary px-2" href="javascript:void(0)"&gt;
  Prepare Submission
  &lt;svg class="icon icon-arrow_cta"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"&gt;&lt;/use&gt;&lt;/svg&gt;
&lt;/a&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>Used to direct attention to a particular action or launch a flow</li>
          <li>Use a plus icon to indicate that the user is about to start a flow</li>
          <li>Use an arrow to indicate that the user will be taken to a next step</li>
          <li>Note the difference between use of a button and a anchor tag. Use anchor tags for navigation, buttons for submitting forms or performing actions</li>
        </ul>
      </div>
    </div>

    <h3 id="utility">Utility</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <button class="btn btn-md btn-secondary mr-1">Validate</button>
          <button class="btn btn-md btn-secondary mr-1">Edit</button>
          <button class="btn btn-md btn-secondary dropdown-toggle" type="button" id="utility-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">More</button>
          <div class="dropdown-menu" aria-labelledby="utility-dropdown">
            <a class="dropdown-item" href="#">Print</a>
            <a class="dropdown-item" href="#">Download</a>
            <a class="dropdown-item" href="#">Delete</a>
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>Show Code</div>
          <pre v-highlight><code class="language-html">
&lt;button class="btn btn-secondary"&gt;
  Validate
&lt;/button&gt;
&lt;button class="btn btn-secondary"&gt;
  Edit
&lt;/button&gt;
&lt;button class="btn btn-secondary dropdown-toggle" type="button" id="utility-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"&gt;
  More
&lt;/button&gt;
&lt;div class="dropdown-menu" aria-labelledby="utility-dropdown"&gt;
  &lt;a class="dropdown-item" href="#"&gt;Print&lt;/a&gt;
  &lt;a class="dropdown-item" href="#"&gt;Download&lt;/a&gt;
  &lt;a class="dropdown-item" href="#"&gt;Delete&lt;/a&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>Used for actions that are utilitary but not part of the primary flow. Least important actions can be grouped in a button dropdown</li>
        </ul>
      </div>
    </div>

    <h3 id="disabled">Disabled</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <button class="btn btn-primary" disabled>Save and Continue</button>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>Show Code</div>
          <pre v-highlight><code class="language-html">
    &lt;button class="btn btn-primary" disabled&gt;
      &lt;svg class="icon icon-search"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-search"&gt;&lt;/use&gt;&lt;/svg&gt;
      Search Inbox
    &lt;/button&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>Buttons should only be disabled if there's a clear reason why</li>
          <li>Form submit buttons should only be disabled when a form is being processed. Users should be able to click submit button to see inline errors</li>
        </ul>
      </div>
    </div>
  </component-frame>
</template>

<script>
export default {
  name: "buttons",
  data() {
    return {
      title: "Buttons",
    };
  },
};
</script>
